.reviewPageContainer {
  margin-bottom: 20px;
  margin-top: 30px;
}

.reviewPageContainer > h3 {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 10px;
}

.balanceTable > thead {
  font-weight: bold;
  text-align: center;
  background-image: linear-gradient(310deg, #2f3a9b 23%, #165d9b 73%);
  background-attachment: fixed;
  color: white;
  font-size: 16px;
}
.balanceTable > thead > tr > th {
  padding: 5px 5px;
}

.balanceTable > tbody > tr > td {
  text-align: center;
  padding: 5px 12px;
  background-color: rgb(255, 255, 255);
}

.balanceTable > tbody > tr > td:first-child {
  font-weight: bold;
  text-align: left;
}
.timerColumn {
  display: flex;
  flex-direction: column;
}

.balanceTable > thead > tr > th:first-child {
  border-radius: 8px 0px 0px 0px;
}

.balanceTable > thead > tr > th:last-child {
  border-radius: 0px 8px 0px 0px;
}

.balanceTable > tbody > tr:last-child > td:last-child {
  border-radius: 0px 0px 8px 0px;
}

.balanceTable > tbody > tr:last-child > td:first-child {
  border-radius: 0px 0px 0px 8px;
}
