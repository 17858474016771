.innerCard {
  padding: 5px;
  display: flex;
  flex-direction: column;
  background: rgb(199, 199, 199);
  margin: 10px 5px;
  min-width: 725px;
  min-height: 250px;
  border-radius: 15px;
}
.header {
  margin: -5px -5px 0px -5px;
  padding: 3px 8px 0px 8px;

  border-radius: 10px 10px 0px 0px;
}

.header > h2 {
  color: white;
}

.teamDetailedBody {
  display: flex;
  flex: 1;
}
.teamStats {
  background-color: white;
  margin: 5px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
}

.teamStats > h4 {
  position: absolute;
  top: 5px;
  left: 5px;
}
.teamAssets > h4 {
  margin-bottom: 5px;
}
.statContainer {
  display: flex;
  flex-direction: column;
  min-width: 360px;
}
.statContainer > table {
  font-size: 13px;
  text-align: center;
}

.rowHeading {
  font-weight: bold;
  text-align: left;
  width: 36%;
}

.statContainer > table > tbody > tr > td {
  margin: 0px 5px;
  border: 1px solid rgb(173, 173, 173);
}
.statContainer > table > thead > tr > th {
  font-weight: bold;
}
.statContainer > table > thead > tr:last-child > th {
  font-weight: normal;
  font-size: 12px;
  min-width: 40px;
}

.currency::first-letter {
  float: left;
  padding-left: 2px;
}
.currency > span {
  float: right;
  padding-right: 2px;
}

.strategyStatement {
  line-height: 20px;
  font-size: 15px;
}
.teamAssets {
  background-color: white;
  overflow-y: auto;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  max-height: 355px;
}

.notThere {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}
.asset {
  display: flex;
  margin: 2px 5px;
  min-height: 25px;
}

.assetDescription {
  border: solid 1.5px;
  min-width: 220px;
  display: flex;
  flex: 1;
  color: black;
  padding: 2px;
  border-radius: 13px;
  margin-right: 5px;
}
.assetDescription > p {
  margin: 3px;
  text-align: center;
  flex: 1;
}
.assetLabel {
  min-width: 100px;
}
.typeSelect {
  height: 10px;
  width: 150px;
}
.typeSelectDropdown {
  width: 150px !important;
}
.button {
  margin: 0px 1px;
}
