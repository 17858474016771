.innerCard {
  background: white;
  margin: 10px 5px;
  padding: 5px;
  height: min-content;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 250px;
  border-radius: 11px;
}

.stepper {
  margin: 5px;
}
.newRoundButton {
  width: min-content;

  margin: 5px;
}

.buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.startGameButtons {
  position: relative;
  display: flex;
  justify-content: center;
}
.auctionNextSwitch {
  justify-self: flex-start;

  margin: 7px;

  min-width: 80px;
}
