.innerCard {
  margin: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap:10px;
}
.innerCard > h3 {
  color: white;
}

.button {
  margin: 5px 0px;
}
