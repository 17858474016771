/* PORTFOLIO STUFF */
.portfolio {
  padding: 20px;
  overflow: auto;
  height: 100%;
  width: 1000px;
}
.portfolio > h1 {
  text-align: center;
  color: white;
}
.portfolio > h2 {
  margin: 15px 10px;
  color: white;
}
.portfolioSummaryRow {
  max-height: 50px;
}
.portfolioSummaryNumber {
  vertical-align: middle;
}
.summaryTable {
  background-color: white;
  border: solid 2px rgb(0, 0, 0);
  padding: 10px;
  border-radius: 15px;
  margin: 0px 10px;
}
.summaryTableCell {
  display: flex;
  align-items: center;
}
.icon {
  margin-right: 5px;
  width: 15%;
}
.icon > img {
  max-width: 100%;
  max-height: 100%;
}

.portfolioRow {
  display: flex;
  background-color: rgb(247 247 247);
  border-radius: 15px;
  border: solid 2px rgb(0, 0, 0);
  align-items: stretch;
  margin: 10px;
  position: relative;
}

.portfolioRow > h1 {
  width: 40px;
  margin: 10px;
}
.portfolioAssets {
  flex: 1;
  margin: 5px !important;
}
