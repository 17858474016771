.innerCard {
  background: white;
  margin: 10px 5px;
  padding: 5px;
  height: min-content;
  display: flex;
  flex-direction: column;
  border-radius: 11px;
}

.saleContainer{

  border-bottom: solid 1px grey;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.auctionMode {
  display: flex;
  align-items: center;
  gap: 10px;
}
.savedAuctionArea {
  display: flex;
  align-items: center;
  margin: auto 0px 5px 0px;
}
.auctionSelect {
  width: 100px;
  margin: 0px 5px;
}

.teamSelect {
  min-width: 100px;
}
.assetPrice {
  min-width: 110px;
  margin: 0px 5px;
}
.assetSell {
  display: flex;
  margin: 5px 0px;
  align-items: center;
}
.assetSell > select {
  flex: 1;
}
.assetSell > button {
  margin-left: auto;
  min-width: 30px;
  margin-right: 2px;
}

.input {
  max-width: 30px;
  padding: 2px;
  text-align: center;
}
.auctionList {
  display: flex;
  flex-direction: column;
  margin: 0px;
}
.auctionItem {
  cursor: pointer;
  display: flex;
}
.auctionItem > p {
  padding: 2px;
  color: rgb(85, 85, 85);
  margin: auto 0px;
  font-size: 13px;
}

.selectedAuctionItem > p {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 13px;
}

.assetPrice {
  max-width: 60px;
}
.assetAdder {
  display: flex;
  margin: 5px 0px;
}
.assetSelector {
  width: 200px;
  margin-right: 5px;
}
.assetSelectorDropdown {
  width: 250px !important;
}
.assetButtons {
  margin-left: auto;
}
.button {
  min-width: 40px;
  margin: 2px;
}
.addButton {
  min-width: 40px;
  margin-left: auto;
  margin-right: 2px;
}
.footerButtons {
  margin-left: auto;
}
