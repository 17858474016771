.innerCard {
  background: white;
  margin: 10px 5px;
  padding: 5px;

  display: flex;
  flex-direction: column;
  border-radius: 11px;
}

.assetView > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.assetView > div > h4 {
  text-align: center;
  margin: 10px;
}
