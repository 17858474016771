.innerCard {
  margin: 5px;
  background-color: rgb(214, 214, 214);
  border-radius: 10px;
}

.innerCard :last-of-type {
  cursor: pointer;
}
.summaryContainer {
  display: flex;
  align-items: stretch;
  border-radius: 10px;
}
.title {
  padding: 5px;
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title > h4 {
  color: white;
}
.title > span {
  color: white;
  margin-right: 2px;
}

.column {
  border-left: 2px solid rgb(211, 211, 211);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  width: 11%;
}

.headingColumn {
  border-left: 2px solid rgb(255, 255, 255);
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  width: 11%;
}
.headingColumn:last-of-type {
  width: 12%;
}
.column:last-of-type {
  width: 12%;
  border-radius: 0px 10px 10px 0px;
}
.headingColumn > p {
  margin-top: -6px;
  font-size: 10px;
}
