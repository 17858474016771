.tradeRequestContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.mobileInfoContainer{
  display: flex;
  flex-direction: column;
  margin-top:20px;
  align-items: center;

  gap:10px;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 5px 0px 5px;
}

.formItem {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  padding-bottom: 10px;
}

.formItem > label {
  font-size: calc(0.5rem + 0.5vw);
  margin-bottom: 3px;

  font-weight: bold;
}

.offerDescription {
  margin: 4px 0px 0px 0px;
}
.dealTypeRadio > label,
.marketRadio > label {
  padding: 0px 10px !important;
}

.volPrice {
  display: flex;
  flex: 1;
}
.volSelect {
  width: 100%;
  min-width: fit-content;
}
.volPrice .formItem {
  width: 40%;
}
.volPrice .formItem:last-of-type {
  width: 60%;
}
.volPrice .formItem:first-of-type {
  margin-right: 5px;
}

.teamSelect {
  width: 150px;
}

.submitButton:hover {
  background-color: green;
  cursor: pointer;
}

.formItem input[type="number"]::-webkit-inner-spin-button,
.formItem input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footerButtons {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.footerButtons > div {
  margin: 5px;
}
