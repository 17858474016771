.leaderboardTableContainer {
  overflow: auto;
  max-height: 300px;
}

.leaderboard > h1 {
  color: rgb(255, 255, 255);
  margin: 20px;
  font-weight: bold;
  text-align: center;
}

.leaderboardTableContainer > table {
  color: white;
  min-width: 700px;
  text-align: center;
}

.leaderboardTableContainer > table > thead > tr > th {
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
}

.leaderboardTableContainer > table > tbody > tr > td {
  font-size: 18px;
  padding: 5px 10px;
}

.leaderboardTableContainer > table > tbody > tr > td:first-child {
  font-weight: bold;
}

.profit {
  min-width: 100px;
}
.profit::first-letter {
  float: left;
  margin-right: 2px;
}
.profit > span {
  float: right;
  margin-left: 2px;
}
