.lastTradedContainer {
  display: flex;
  flex-direction: column;
  margin: 5px 10px 5px 10px;
  align-items: center;
}

.lastTradedContainer > table {
  margin: 5px;
  min-width: 200px;
}
.lastTradedContainer > table > thead > tr > th {
  border: none !important;
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: rgb(12, 160, 73);
}
.lastTradedContainer > table > thead > tr > th:first-child {
  border-top-left-radius: 10px;
}
.lastTradedContainer > table > thead > tr > th:last-child {
  border-top-right-radius: 10px;
}

.lastTradedContainer > table > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}
.lastTradedContainer > table > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}

.lastTradedContainer > table > tbody > tr > td {
  text-align: center;
  background-color: white;
  padding: 4px 10px;
}
