.gamePlayAreaContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 1310px;
}
.dashboard {
  min-height: 175px;
  background: #eaeaea;
  margin: 10px;
  border-radius: 10px;
  border: 3px solid;
  min-width: 1310px;
}
.currentTab {
  display: flex;
  width: 100%;
  max-height: calc(100% - 195px);
  flex: 1;
}
.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
  max-width: 700px;
  line-height: 30px;
}
.descriptionText {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
  max-width: 700px;

  opacity: 0;
  animation: fadeIn 1s normal forwards ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.button {
  width: 200px;
  font-size: 18px;
  height: auto;
  opacity: 0;
  animation: fadeIn 1s normal forwards ease-in-out;
}

.tradingWrapper .sectionTitle {
  margin-right: 20px;
}
.generation {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20%;
  /* overflow: scroll; */
  min-width: fit-content;
}
.tradingWrapper {
  flex: 1;
  /* overflow: scroll; */
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 854px;
  position: relative;
}
.autoTraderPaused {
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.747);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;

  -moz-box-shadow: 0px 0px 10px #000000;
  -webkit-box-shadow: 0px 0px 10px #000000;
  box-shadow: 0px 0px 10px #000000;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.autoTraderPaused > h1 {
  color: white;
}

.centerWrapper {
  flex: 1;

  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 550px;
}

.customers {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20%;
  /* overflow: scroll; */
  min-width: fit-content;
}
.noAssets {
  color: white;
  margin-top: 50px;
}
