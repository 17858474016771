.dealContainer {
  width: 100%;
  justify-content: center;
  display: flex;
}

.buyVolume {
  color: rgb(0, 148, 0);
}
.buyVolume > li > div > div {
  color: rgb(192, 192, 192); /* doesn't work */
  font-weight: 500;
}


