.switchContainer {
  position: absolute;
  top: -67px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}

.switcher {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 2px solid black;
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  border-radius: 0px 0px 10px 10px;
}
.switcher > h5 {
  color: white;
}
.buttonRow {
  display: flex;
  max-width: 90vw;
  overflow: hidden;
}
.buttonRow:hover {
  overflow-x: auto;
}
.buttonRow > button {
  margin: 5px;
  border: 2px solid red;
  background-color: red;
  color: white;
  transition: 0.5s;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  min-width: fit-content;
}

.buttonRow > button:hover {
  color: black !important;
  background-color: white !important;
}

.showHideSwitch {
  border-radius: 0px 0px 5px 5px;
  height: 15px;
  width: 35px;
  border: solid 1px white;
  border-top: none;
  color: white;
  background: rgb(5 75 142);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showHideSwitch:focus {
  outline: none;
  box-shadow: none;
}
.icon {
  font-size: 13px;
}
