.innerCard {
  background: white;
  margin: 10px 5px;

  padding: 5px;

  border-radius: 11px;
}

.headings {
  display: flex;
  margin-bottom: 5px;
}

.headings :first-child {
  width: 20%;
}
.headings :last-child {
  width: 80%;
}
.row {
  display: flex;
  align-items: center;
}

.row > h6 {
  text-align: center;
}
.round {
  width: 20%;
}
.button {
  margin: 2px;
  margin-left: auto;
}
.scenario {
  width: 80%;
}

.adder {
  margin-top: 5px;
}

.modalInfo {
  display: flex;
  flex-direction: column;
  margin: 2px 0px;
}
