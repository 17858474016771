.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}

.forInfo {
  font-size: 14px;
  color: white;
  margin: 5px;
}
.logoContainer {
  background: white;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 20px;
  max-width: 30%;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}

.scoreBoard {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.userTimes {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userTimes > h1 {
  color: white;
  margin: 20px 0px 0px 0px;
  font-weight: bold;
}

.userTimes > table {
  color: white;
  min-width: 500px;
  text-align: center;
  margin: 10px;
  border: white 2px solid;
  box-shadow: 0px 0px 10px 0px rgb(53, 53, 53);
}

.userTimes > table > thead > tr > th {
  font-weight: bold;
  font-size: 22px;
  padding: 10px;
}

.userTimes > table > tbody > tr > td {
  font-size: 20px;
  padding: 10px;
}

.totals {
  display: flex;
  width: 100%;
  margin: 0px 20px;
}
.totals > div {
  flex: 1;
}
.totals > div {
  flex: 1;
}
.leaderboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 350px;
}

.button {
  margin: 20px;
}
