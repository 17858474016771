.innerCard {
  border: solid 4px;
  box-shadow: 0 0 5px #3d484941;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  margin: 10px;
  padding: 8px 8px 4px 8px;
  max-width: 270px;
  min-width: 250px;
}

.detailedView > hr {
  width: 70%;
}

.header {
  display: flex;
}
.icon {
  margin-right: 5px;
  width: 30%;
}
.icon > img {
  max-width: 100%;
  max-height: 100%;
}
.headingInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headingInfo > h4 {
  text-align: center;
  line-height: 22px;
}
.headingInfo > h5 {
  text-align: center;
  padding: 5px 0px;
}

.controls {
  display: flex;
}

table,
th,
td {
  border: none;
  padding: 3px;
}

.marketLabel {
  min-width: 45px;
}
.loadValue {
  min-width: 88px;
}

.energyMarketControls {
  display: flex;
}

.loadSelect {
  letter-spacing: 0.5px;
  padding: 0px;
  width: 80px;
  text-align: center;
}
.showDetailsText {
  cursor: pointer;
}
.spacer {
  display: flex;
  margin: 3px 0px;
  flex: 1;
  justify-content: center;
}
.spacer > hr {
  width: 25%;
}
.notes {
  text-align: center;
  margin-bottom: 10px;
}
.extraInfo {
  display: flex;
  flex: 1;
  margin: 10px 0px;
}
.extraInfo > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.extraInfo > div > p:nth-child(2) {
  margin-bottom: 5px;
}

.maxCombinedLoad {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
