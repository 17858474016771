.container {
  margin-top: 5px;
  margin-left: 5px;
}

.innerCard {
  background: white;
  margin: 10px 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: min-content;
  border-radius: 11px;
}

.table {
  font-size: 13px;
}

.table > thead > tr > th {
  font-weight: bold;
  text-align: center;
}
.table > thead > tr :first-child {
  text-align: left;
}

.table > tbody > tr > td {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}
.table > tbody > tr > td:hover {
  cursor: pointer;
}
.table > tbody > tr :first-child {
  text-align: left;
}

.table > tbody > tr :first-child:hover {
  transform: unset;
  font-weight: unset;
  cursor: unset;
}
.plusRow {
  display: flex;
  position: relative;
  justify-content: right;
  align-items: center;
  padding: 4px;
}

.row {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.warningRow {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px solid red;
  border-radius: 5px;
}

.input {
  border: none;
  width: calc(100% - 10px);
  height: 32px;
  text-align: center;
  padding: 0px;
  cursor: text !important;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.save {
  position: absolute;
  right: 0;
  z-index: 5;
}
.save:hover {
  transform: scale(1.2);
  color: green;
}

.button {
  clear: left;
  float: right;
  color: white;
}

.line {
  width: 100%;
}

.deleteContainer {
  width: 3%;
  justify-self: flex-end;
  float: right;
}
.delete {
  padding-top: 7px;
  /* margin-left: 5px; */
}
.delete:hover {
  color: rgb(196, 0, 0);
}

.select {
  border: none;
  width: calc(100% - 10px);
  height: 32px;
  text-align: center;
  padding: 0px;
}

.left {
  width: 30px;
  text-align: center;
}

.property {
  width: 39%;
  text-align: center;
}

.middle {
  width: 21%;
  text-align: center;
}

.right {
  width: 30%;
  text-align: center;
}

.autocomplete {
  border: none;
  width: calc(100% - 10px);
  height: 32px;
  text-align: center;
  padding: 0px;
  cursor: text !important;
}

.autocompleteGrey {
  border: none;
  width: calc(100% - 10px);
  height: 32px;
  text-align: center;
  padding: 0px;
  cursor: text !important;
  background-color: #c1c1c1 !important;
}

.autocompleteGrey > div {
  background-color: #c1c1c1 !important;
}
