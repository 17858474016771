.dashboardContainer {
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  color: black;
}
.roundAndWeather {
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-top: 5px;
  padding-right: 24px;
}
.roundAndWeather > h3 {
  margin: 5px 10px;
}

.accountSheet {
  text-align: center;
  position: relative;
}
.accountSheet > h3 {
  margin-top: 0px;
  text-align: left;
}

.accountsTable {
  margin-right: 10px;
  position: relative;
  min-width: 670px;
}
.accountsTable > thead {
  font-weight: bold;
  text-align: center;
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  background-attachment: fixed;
  color: white;
  font-size: 16px;
}

.accountsTable > tbody > tr > td {
  text-align: center;
  padding: 5px 10px;
  background-color: rgb(255, 255, 255);
}

.accountsTable > tbody > tr > td::first-letter {
  float: left;
  margin-right: 2px;
}
.accountsTable > tbody > tr > td > span {
  float: right;
  margin-left: 2px;
}

.accountsTable > tbody > tr > td:first-child {
  font-weight: bold;
  text-align: left;
  background-color: rgb(255, 255, 255);
}
.accountsTable > tbody > tr > td:nth-child(2) {
  font-weight: 500;
  background-color: rgb(255, 255, 255);
}

.accountsTable > tbody > tr > td:first-child::first-letter {
  float: none;
  margin-right: 0px;
}

.accountsTable .gap {
  background: rgba(255, 255, 255, 0);
  padding: 5px 3px 5px 0px;
  color: #004e92;
}
.accountsTable .gap > span {
  font-weight: bold;
}

.breakdownHeading {
  border: none !important;
  background: #004e92 !important;
  color: white;
}
.breakdownHeading::first-letter {
  margin-right: 0px !important;
  float: none !important;
}

.teamName {
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.teamName:hover {
  transform: scale(1.1);
}
.balanceTable > thead {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  background-attachment: fixed;
  color: white;
  font-size: 16px;
}
.balanceTable > thead > tr > th {
  padding: 5px 5px;
}

.balanceTable > tbody > tr > td {
  text-align: center;
  padding: 5px 5px;
  background-color: rgb(255, 255, 255);
}

.balanceTable > tbody > tr > td:first-child {
  font-weight: bold;
  text-align: left;
}
.timerColumn {
  display: flex;
  flex-direction: column;
}
.accountsTable > thead > tr > th:first-child,
.accountsTable > tbody > tr:first-child > td:nth-child(4),
.balanceTable > thead > tr > th:first-child {
  border-radius: 8px 0px 0px 0px;
}
.accountsTable > thead > tr > th:last-child,
.accountsTable > tbody > tr:first-child > td:last-child,
.balanceTable > thead > tr > th:last-child {
  border-radius: 0px 8px 0px 0px;
}

.accountsTable > tbody > tr:last-child > td:last-child,
.accountsTable > tbody > tr:nth-child(3) > td:last-child,
.balanceTable > tbody > tr:last-child > td:last-child {
  border-radius: 0px 0px 8px 0px;
}
.accountsTable > tbody > tr:nth-child(3) > td:nth-child(4),
.accountsTable > tbody > tr:last-child > td:first-child,
.balanceTable > tbody > tr:last-child > td:first-child {
  border-radius: 0px 0px 0px 8px;
}
.timerColumn > h3 {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.lastColumn {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  align-items: center;
}

.logoContainer {
  max-width: 80%;
  margin-bottom: 8px;
}
.logoContainer > h5 {
  text-align: center;
  color: #2f367a;
  font-weight: bold;
  margin-bottom: 8px;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}
