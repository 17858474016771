.commentsArea {
  width: 300px;
  float: right;
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  border: 0px solid #efefef;
  border-left: 2px solid;
  height: 100%;

  position: fixed;
  transition: 0.5s;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.popout_commentsArea {
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  width: 100%;
  border: 0px solid #efefef;
  height: 100vh;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}
.commentsFeedContainer {
  flex: 1;
  position: relative;
  height: calc(100% - 200px) !important;
}
.commentsFeedContainer > div {
  height: 100% !important ;
}
.header {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  width: 100%;
  min-height: 35px;
}
.headerButtons {
  margin-left: auto;
}
.headerButtons > button {
  margin: 5px;
}

.innerChatWindow {
  display: flex;
  flex-direction: column;
}

.commentInputArea {
  padding: 0px 10px;
  width: 100%;
}
.buttonGuard {
  width: 35px;
  position: absolute;
  top: 43%;
  height: 60px;
  left: -35px;
}
.showHideChat {
  position: absolute;
  top: 25%;
  left: 19px;
  border-radius: 5px 0px 0px 5px;
  height: 35px;
  width: 15px;
  border: solid 1px white;
  border-right: none;
  color: white;
  background: rgb(5 75 142);
  cursor: pointer;
}
.showHideChat:focus {
  outline: none;
  box-shadow: none;
}
.icon {
  margin-left: -4px;
  font-size: 13px;
}

.logoContainer {
  background: white;
  padding: 5px 10px;
  border-radius: 20px;
  max-width: 150px;
  align-self: center;
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.splitter {
  border-bottom: rgb(255, 255, 255) 4px solid !important;
  border-top: rgb(0, 0, 0) 0px none !important;
  background-color: #000000 !important;
  border-style: double !important;
}
.slitterDots {
  position: absolute;
}
