.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1310px;
  width: 100%;
  height: 100vh;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  box-shadow: 0 0 20px rgb(61, 61, 61);
  background: white;
  max-width: 500px;

  padding: 30px;
}
.requiresLogin {
  display: flex;
  flex-direction: column;
}
.requiresLogin > h2 {
  margin: 10px;
}
.logo {
  width: 100%;
  max-width: 250px;
}
.innerContainer > h2 {
  margin-top: 20px;
  background: linear-gradient(310deg, #232a6e 23%, #0066be 73%);
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.welcomeMessage {
  font-size: 16px;
  text-align: center;
  margin: 20px 0px;
  line-height: 20px;
}
.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginForm > label {
  margin: 10px 0px 5px 0px;
}

.trainingSwitch {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  border: solid 2px rgb(114, 114, 114);
  margin-bottom: 10px;
}

.inputBox {
  width: 300px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  margin: 2px 5px 5px 5px;
  border: solid 2px rgb(114, 114, 114);
  font-family: unset;
  text-align-last: center;
}

.inputBox:focus {
  outline: none;
}

.submitButton {
  height: 35px;
  width: 170px;
  transform: scale(1);
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  margin: 5px;
  border: solid 2px white;
  color: white;

  filter: grayscale(100%);
  background-image: linear-gradient(310deg, #232a6e 23%, #0066be 73%);
  transition: 0.5s;
}

.skipButton {
  height: 35px;
  width: 170px;
  transform: scale(1);
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  margin: 5px;
  border: solid 2px white;
  color: white;
  background-image: linear-gradient(310deg, #32912f 23%, #2c9c09 73%);
  transition: 0.5s;
}

.submitButton:focus {
  outline: none;
}
.submitButton:hover,
.skipButton:hover {
  cursor: pointer;
  color: white;
  transform: scale(1.1);
  font-size: 1em;

  font-size: 1.3em;
  filter: grayscale(0%);
}

.formSucess {
  color: green;
  font-weight: bold;
  font-size: 1.2em;
  margin: 5px;
}
.formError {
  color: rgb(206, 0, 0);
  font-weight: bold;
  font-size: 1.2em;
  margin: 5px;
  text-align: center;
}
.leaderboardContainer {
  display: flex;
  justify-content: flex-start;
  min-width: 650px;
  height: 100%;
  margin-top: 5%;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  overflow: scroll;
}
