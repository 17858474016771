.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.row > h6 {
  text-align: center;
}
.round {
  width: 20%;
}
.button {
  margin: 2px;
  margin-left: auto;
}
.scenario {
  text-align: center;
  width: 80%;
}
