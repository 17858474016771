.rowContainer {
  display: flex;
  align-items: stretch;
  background-color: rgb(226, 226, 226);
  border-radius: 10px;
  border: solid 2px rgb(82, 82, 82);
  width: 100%;
  margin: 2px 0px;
}

.column {
  border-left: 2px solid rgb(82, 82, 82);
  padding: 4px 5px;
  display: flex;
  align-items: center;
}

.column > p {
  text-align: center;
  width: 100%;
  font-size: 16px;
}
.volume {
  flex: 1;
}
.price {
  width: 29%;
}
.deal {
  border-left: none;
  width: 22%;
}
.deal > p {
  font-weight: bold;
}

.dealContainer {
  width: 100%;
  justify-content: center;
  display: flex;
}

.deleteButton {
  margin-left: 5px;
  border: 1px solid rgb(204, 0, 0);
  color: rgb(204, 0, 0);
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  border-radius: 15px;
  padding: 2px 10px;
  transition: 0.5s;
  cursor: pointer;
}

.deleteButton:hover {
  margin-left: 5px;
  border: 1px solid rgb(204, 0, 0);
  background-color: rgb(204, 0, 0);
  color: white;
  font-weight: bold;
}
