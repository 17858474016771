.container {
  margin-top: 5px;
  margin-left: 5px;
}
.innerCard {
  background: white;
  margin: 10px 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: min-content;
  border-radius: 11px;
}

.table {
  font-size: 13px;
}

.table > thead > tr > th {
  font-weight: bold;
  text-align: center;
}
.table > thead > tr :first-child {
  text-align: left;
}

.table > tbody > tr > td {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}
.table > tbody > tr > td:hover {
  cursor: pointer;
}
.table > tbody > tr :first-child {
  text-align: left;
}

.table > tbody > tr :first-child:hover {
  transform: unset;
  font-weight: unset;
  cursor: unset;
}
.row {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.input {
  border: none;
  width: 50px;
  text-align: center;
  padding: 0px;
  cursor: text !important;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.save {
  position: absolute;
  right: 0;
  z-index: 5;
}
.save:hover {
  transform: scale(1.2);
  color: green;
}
