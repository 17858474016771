.tradeTableContainer {
  padding: 5px;
  margin: 5px;
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  min-width: min-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: transparent 3px solid;
}
.summaryContainer {
  display: flex;
  align-items: stretch;
  background-color: rgb(226, 226, 226);
  border-radius: 10px;
  border: solid 2px rgb(82, 82, 82);
  width: 100%;
  margin: 2px 0px;
}

.heading {
  text-align: center;
  margin-bottom: 8px;
}

.noTrades {
  text-align: center;
  padding-top: 10px;
}
.noTrades > h5 {
  margin-top: auto;
}

.tradeRowContainerSell {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.tradeRowContainerBuy {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.arrow {
  margin: 0px 10px;
}

.dealButton {
  margin-left: 5px;
  border: 1px solid green;
  color: green;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  border-radius: 15px;
  padding: 0px 10px;
  transition: 0.5s;
  cursor: pointer;
}
.dealButton:hover {
  margin-left: 5px;
  border: 1px solid green;
  background-color: green;
  color: white;
  font-weight: bold;
}
.sellTitle {
  font-size: 14px;
  cursor: pointer;
}
.buyTitle {
  margin-top: auto;
  font-size: 14px;
  cursor: pointer;
}
