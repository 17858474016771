.teamRow {
  display: flex;
  width: 300px;
}

.teamRow > h5 {
  min-width: 120px;
  text-align: left;
}

.teamRow > h6 {
  min-width: 90px;
  text-align: left;
}
.randomiseButton {
  margin: 20px;
}
.presetButton {
  margin: 0px 5px;
}
