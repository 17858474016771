.marketLabel {
  text-align: center;
  margin-bottom: 5px;
}
.tableHeadings {
  text-align: center;
  font-weight: bold;
}
.tableItems {
  text-align: center;
}
