.commentsFeed {
  /* -moz-box-shadow: inset 0 0 10px grey;
    -webkit-box-shadow: inset 0 0 10px grey;
    box-shadow: inset 0 0 10px grey; */

  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.showMore {
  color: white !important;
  border-color: white !important;
  margin: 5px 32%;
}
