.replyContainer {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.replyContainer > button {
  margin-left: 10px;
  border-width: 2px;
  font-weight: bold;
}

.replyContainer > textarea {
  border-radius: 15px;
}
