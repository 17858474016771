.reviewPageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-image: linear-gradient(310deg, #0f1975 23%, #114877 73%); */
}

.header > h2 {
  color: white;
  margin-top: 20px;
}
.header {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 80px;
  align-items: center;
}

.chartSelect {
  margin: 10px;
  width: 200px !important;
}

.chartAndTables {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.chartAndTables > h3 {
  color: white;
  font-weight: bold;
  margin: 10px;
}

.chartInnerCard {
  background: #fff;
  min-width: 0;
  min-height: 0;
  padding: 10px;
  position: relative;
  box-shadow: 0px 0px 10px #3d4849;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.button {
  border-radius: 10px;
  margin: 10px;
  padding: 5px;
  flex: 1;
  border: none;
  color: white;
  cursor: pointer;
  transition: 0.5s;
}
.button:hover {
  transform: scale(1.1);
}

.buttonRow {
  display: flex;
  width: 60%;
}
