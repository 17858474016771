p {
  font-size: 12px;
}
.noTeamSelected {
  padding: 5px;
  display: flex;
  flex-direction: column;
  background: rgb(199, 199, 199);
  margin: 10px 5px;
  min-width: 725px;
  min-height: 250px;
  border-radius: 15px;
  align-items: center;
}
.controlAreaContainer {
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px 0px 5px;
}

.controlAreaInner {
  display: flex;
  /* background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%); */
  min-height: fit-content;
}

.setupArea {
  width: min-content;
}
.controlAreaContainer > h1 {
  color: white;
  margin: 10px;
}

.eventLog {
  min-width: 50%;
}

.timmerControls {
  display: flex;
  margin: 5px;
}

.logsHeader {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100px;
  justify-content: center;
}

.logsHeader > h2 {
  color: white;
  margin-top: 20px;
}
.logs {
  display: flex;
  flex: 1;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;

  height: 100vh;
  width: 50%;
}
.column > h3 {
  text-align: center;
  color: white;
}

.controlColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3px;
  height: fit-content;
}
.controlColumn:nth-last-child(2) {
  min-width: 330px;
  max-width: 380px;
}
.controlColumn:last-of-type {
  min-width: 350px;
  max-width: 400px;
}

.logoContainer {
  background: white;
  padding: 5px 10px;
  border-radius: 20px;
  max-width: 70%;
  align-self: center;
}
.logoContainer > h2 {
  text-align: center;
  color: #2f367a;
  font-weight: bold;
  margin-bottom: 8px;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}

.tradeTablesContainer {
  display: flex;
  width: 100%;
}
.tradesTable {
  display: flex;
  flex-direction: column;
  min-width: 33%;
}
.tradesTable > h3 {
  color: white;
  text-align: center;
  margin-bottom: 0px;
}
