.innerContainer {
  margin: 0px 5px;
  min-width: 815px;
  position: relative;
}
.helpButton {
  position: absolute;
  top: 10px;
}
.helpButton,
.helpButton:hover,
.helpButton:focus {
  color: white;
}
.heading {
  text-align: center;
}
.tradingArea {
  display: flex;
}
.tradeRequestContainer {
  margin: 8px;
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  padding: 5px;
  width: 250px;
  display: flex;
  flex-direction: column;
}
.tradesTable {
  display: flex;
  flex-direction: column;
}

.tradesTable > h4 {
  color: white;
}

.submitButton {
  height: 40px;
  font-size: 16px;
  line-height: 15px;
  background-color: rgb(12, 72, 184);
  color: white;
}

.submitButton:hover {
  background-color: green;
  cursor: pointer;
}

.tradingArea table {
  border-collapse: collapse;
}
.tradingArea th {
  /* border: solid 0.5px rgb(163, 163, 163); */
  padding: 5px;
}

.myTradesContainer {
  margin: 5px;
  padding: 10px;
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  min-width: 275px;
  max-height: 400px;
}
.myTradeMarkets {
  display: flex;
  justify-content: center;
}
.myTradesMarket {
  max-height: 250px;
  min-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 15px;
}
.sectionTitle {
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 2px;
}
