.footer {
  display: flex;

  line-height: 23px;
  justify-content: flex-end;
  margin-right: 10px;
}

.spacer {
  margin: 0px 2px;
}
.likeButton {
  cursor: pointer;
  margin-left: 5px;
  font-size: 1em;
}

.likeButton > input {
  opacity: 0;
  width: 0;
  height: 0;
}
.replyButton {
  cursor: pointer;
}

.orignalReplyContainer {
  border-radius: 10px;
  background-color: rgb(207, 207, 207);
  padding: 5px;
  padding-left: 10px;
  -moz-box-shadow: inset 0 0 10px grey;
  -webkit-box-shadow: inset 0 0 10px grey;
  box-shadow: 0 0 5px grey;
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 0px;
  border-width: 2px;
  border-style: solid;
}

.replyingToText {
  text-align: left;
  margin: 0px;
  font-style: italic;
  font-size: 0.7em;
}

.orignalReplyComment {
  text-align: right;
  font-size: 0.8em;
}
.commentContainer {
  border-radius: 15px;
  margin: 10px 5px;
  padding-left: 10px;
}
.individualReply {
  padding-top: 5px;
  background-color: rgb(235, 235, 235);
  border-width: 3px;
  border-style: solid;
  box-shadow: 0 0 2px grey;
}

.individualComment {
  border-width: 3px;
  background-color: rgb(235, 235, 235);
  border-style: solid;
  box-shadow: 0 0 2px rgb(0, 0, 0);
}

.userName {
  font-weight: bold;
  font-size: 0.8em;
  padding-top: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(37, 37, 37);
}

.comment {
  margin: 2px 2px 0px 0px;
  font-size: 0.8em;
  overflow-wrap: break-word;
  line-height: 13px;
}

.notification {
  font-style: italic;
  text-align: left;
  font-size: 15px;
  margin-left: 8px;
  color: white;
}
.timeElapsed {
  min-width: 23px;
}
