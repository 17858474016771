.summaryContainer {
  display: flex;
  align-items: stretch;

  border-radius: 10px;

  width: 100%;
  margin: 2px 0px;
  border: solid 2px black;
  background-color: rgb(12, 160, 73);
}

.headingColumn {
  border-left: 2px solid rgb(255, 255, 255);
  padding: 4px 0px;
}
.headingColumn > h6 {
  color: white;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.headingColumn:first-of-type {
  border-left: none;
}
