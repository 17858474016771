.innerCard {
  background: white;
  margin: 5px;
  padding: 5px;
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.timeInput {
  width: 80%;
}

.footer {
  display: flex;
}
.footer > button {
  flex: 1;
  margin: 5px;
}
