.innerCard {
  background: white;
  margin: 10px 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 255px;
  border-radius: 11px;
}

.optionContainer {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  min-width: 260px;
}
.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
}
.name {
  margin-right: 20px;
}
.input {
  width: 50px;
  margin: 5px 0px;
  margin-left: auto;
  text-align: center;
  border-radius: 5px;
}
.switch {
  width: 50px;
  margin: 5px 0px;
  margin-left: auto;
  text-align: center;
}

.saveButton {
  width: min-content;
  margin: 5px;
}
.footer {
  display: flex;
  align-items: center;
  margin-top: auto;
  flex-direction: column;
}
.locker {
  font-size: 2em;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
}
.setupButton {
  width: min-content;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
