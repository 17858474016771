.container {
  width: fit-content;
}

.targetContainer {
  display: flex;
  align-items: center;
}
.targetContainer > p {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
.icon {
  font-size: 18px;
  margin-right: 10px;
}
