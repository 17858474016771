.inputRow {
  margin: 5px 0px;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}
.inputRow > textarea {
  width: 100%;
  border: solid 2px rgb(211, 211, 211);
  border-radius: 15px;
}
.inputRow > button {
  margin-left: 10px;
  color: white;
  font-weight: bold;
  border-width: 2px;
  height: min-content;
}

.recipientSelect {
  min-width: 140px;
}

.recipientSelect > div {
  border-radius: 15px !important;
}

.alert {
  text-align: center;
  color: white;
  margin-bottom: 5px;
}
