.container {
  padding: 5px;
  margin: 5px;
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  min-width: min-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: transparent 3px solid;
}

.storageTable {
  margin: 10px;
}
.storageTable > thead {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-image: linear-gradient(310deg, #2f367a 23%, #004e92 73%);
  background-attachment: fixed;
  color: white;
  font-size: 16px;
}
.storageTable > thead > tr > th {
  padding: 5px 5px;
}

.storageTable > tbody > tr > td {
  text-align: center;
  padding: 5px 5px;
  background-color: rgb(255, 255, 255);
}

.storageTable > tbody > tr > td:first-child {
  font-weight: bold;
  text-align: left;
}

.storageTable > thead > tr > th:first-child {
  border-radius: 8px 0px 0px 0px;
}

.storageTable > thead > tr > th:last-child {
  border-radius: 0px 8px 0px 0px;
}

.storageTable > tbody > tr:last-child > td:last-child {
  border-radius: 0px 0px 8px 0px;
}

.storageTable > tbody > tr:last-child > td:first-child {
  border-radius: 0px 0px 0px 8px;
}

.storageTable > tbody > tr > td > span {
  float: right;
  margin-left: 5px;
  line-height: 16px;
  font-size: 12px;
}
.storageTank {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storageTank > img {
  max-width: 80%;
  margin: 10px;
}
